export const getLocal = (name) => {
  return JSON.parse(localStorage.getItem(name) || '[]' )
}

export const setLocal = (name, value) => {
  localStorage.setItem(name, JSON.stringify(value))
}

export const getLocalString = (name) => {
  return localStorage.getItem(name) || '' 
}

export const setLocalString = (name, value) => {
  localStorage.setItem(name, value)
}