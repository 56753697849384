import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
  {
    name: 'notFound',
    path: '/:path(.*)+',
    redirect: {
      name: 'home',
    },
    meta: {
      title: 'home', 
      webpath: 'index',
    },
  },
  {
    name: 'home',
    path: '/',
    component: () => import('@/view/home'),
    meta: {
      title: 'home',
      webpath: 'index',
    },
  },
  {
    name: 'about-index',
    path: '/about-index',
    component: () => import('@/view/about/index'),
    meta: {
      title: 'about',
      webpath: 'about/index',
    },
  },
  {
    name: 'about-contact',
    path: '/about-contact',
    component: () => import('@/view/about/contact'),
    meta: {
      title: 'contact',
      webpath: 'about/contact',
    },
  },
  {
    name: 'bar',
    path: '/bar',
    component: () => import('@/view/bar'),
    meta: {
      title: 'products',
      webpath: 'bar/index',
    },
  },
  {
    name: 'bar-search',
    path: '/bar-search',
    component: () => import('@/view/bar/search'),
    meta: {
      title: 'products',
      webpath: 'bar/search',
    },
  },
  {
    name: 'bar-clist',
    path: '/bar-clist',
    component: () => import('@/view/bar/clist'),
    meta: {
      title: 'products',
      webpath: 'category/',
    },
  },
  {
    name: 'goods',
    path: '/goods',
    component: () => import('@/view/goods'),
    meta: {
      title: 'products',
      webpath: 'product/',
    },
  },
];

const router = createRouter({
  routes,
  history: createWebHashHistory(),
});

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  next();
});

export { router };
