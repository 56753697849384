import { getLocalString,setLocalString } from '@/utils/utils.js'
import { getToken, setToken } from '@/utils/auth'

export default {
  state:{
    token:getToken(),
    fromUrl:getLocalString('fromurl')
    //cart:[]
  },
  mutations:{
    setToken(state,token){
      state.token = token      
      setToken(token)
    },
    setFromUrl(state,url){
      state.fromUrl = url      
      setLocalString('fromurl',url)
    }
  },
  actions:{
    
  },
  getters:{
    getToken(state){
      return state.token
    },  
    isLogin(state) {
      return !!state.token  
    }
  },
  namespaced:true
}
