<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  mounted() {
    this.$router.isReady().then(() => {
      // 根据不同路由跳转不同页面
      let routes = this.$router.getRoutes();
      // let currentRoute = ref(this.$router.currentRoute);
      let routerPath = "";
      if (typeof this.$router.currentRoute.value.path !== "undefined")
        routerPath = this.$router.currentRoute.value.path;
      let url = "";
      // console.log(this.$router.currentRoute._value);
      routes.forEach((item) => {
        if (routerPath == item.path) {
          if (routerPath == "/bar-clist") {
            if (
              Object.prototype.hasOwnProperty.call(
                this.$router.currentRoute.value.query,
                "catid"
              )
            ) {
              let goodid = this.$router.currentRoute.value.query.catid;
              url = item.meta.webpath + goodid + ".html";
            }
            //
          } else if (routerPath == "/goods") {
            if (
              Object.prototype.hasOwnProperty.call(
                this.$router.currentRoute.value.query,
                "id"
              )
            ) {
              let goodid = this.$router.currentRoute.value.query.id;
              url = item.meta.webpath + goodid + ".html";
            }
            //
          } else {
            url = this.$router.currentRoute.value.fullPath.replace(
              routerPath,
              item.meta.webpath
            );
          }
        }
      });

      if (!this._isMobile()) {
        //alert(process.env.VUE_APP_BASE_URL + url);
        //window.location.href = process.env.VUE_APP_BASE_URL + url;
      }
    });
  },
  methods: {
    // 判断是否是手机端，如果是，返回true
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
};
</script>
<style>
body {
  font-size: 16px;
  background-color: #f8f8f8;
  -webkit-font-smoothing: antialiased;
}
.tl {
  text-align: left;
}
.tr {
  text-align: right;
}
/* CDN 服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
  font-family: "iconfont"; /* Project id 3624376 */
  src: url("//at.alicdn.com/t/c/font_3624376_amk4lwk3v3e.woff2?t=1662025663313")
      format("woff2"),
    url("//at.alicdn.com/t/c/font_3624376_amk4lwk3v3e.woff?t=1662025663313")
      format("woff"),
    url("//at.alicdn.com/t/c/font_3624376_amk4lwk3v3e.ttf?t=1662025663313")
      format("truetype");
}
.my-icon {
  font-family: "iconfont";
  font-style: normal;
  color: #10aeff;
}

.my-icon-jiankangguanli:before {
  content: "\e62a";
}

.my-icon-daohang:before {
  content: "\e684";
}

.my-icon-shilibiao:before {
  content: "\e636";
}

.my-icon-yanjing:before {
  content: "\e65f";
}

.my-icon-qinziyou:before {
  content: "\e637";
}
</style>
