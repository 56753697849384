import { setLocal,getLocal } from '@/utils/utils.js'

export default {
  state:{
    cart:getLocal('cart')
    //cart:[]
  },
  mutations:{
    getData(state){
      return state.cart
    },
    addShopcart(state,goodsinfo){
      let flag = false
      state.cart.some(item => {
        if(item.id === goodsinfo.id)
        {
          item.count = parseInt(goodsinfo.count)
          item.select = goodsinfo.select
          flag = true
          return true
        }
      })
      if(!flag){
        state.cart.push(goodsinfo)
      }
      setLocal('cart',state.cart)
    },
    updateGoodsinfo(state,goodsinfo){
      state.cart.some(item=>{
        if(parseInt(item.id) === parseInt(goodsinfo.id))
        {
          item.count = parseInt(goodsinfo.count)
        }
        return true
      })
      setLocal('cart',state.cart)
    },
    clearGoodsinfo(state,ids){
      let temp = [];
      state.cart.forEach(item=>{
        if(ids.indexOf(item.id) == -1)
        {
          item.select = false
          temp.push(item)
        }
      })
      state.cart = temp
      setLocal('cart',state.cart)
    },
    updateGoodsSelected(state,goodsinfo){
      state.cart.some(item=>{
        if(parseInt(item.id) === parseInt(goodsinfo.id))
        {
          item.select = goodsinfo.select
        }
        return true
      })
      setLocal('cart',state.cart)
    }
  },
  actions:{
    
  },
  getters:{
    getData(state){
      return state.cart
    },
    getGoodsCount(state){
      const goods = {}
      state.cart.forEach(element => {
        goods[element.id] = element.count
      });
    },
    getGoodsSelected(state){
      const goods = {}
      state.cart.forEach(element => {
        goods[element.id] = element.selected
      });
      return goods;
    }
  },
  namespaced:true
}
