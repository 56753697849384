import { createApp } from 'vue';
import { ActionBar, ActionBarIcon, ActionBarButton, Divider, NavBar,Popup, Overlay, Loading, Dialog, ContactCard, Form, AddressEdit, AddressList, Field, CellGroup, Cell, SwipeCell, Icon, Stepper, Card, Checkbox, CheckboxGroup, Button,Image, Swipe, SwipeItem, PullRefresh, List, Tab, Tabs, Tag, TreeSelect, SubmitBar, Toast, Skeleton, Search  } from 'vant'
import App from './App.vue';
import store from './store'
import { router } from './router';
import { Lazyload } from 'vant';
import 'vant/lib/index.css'; // 全局引入样式

const app = createApp(App);
app.use(ActionBarButton)
  .use(ActionBarIcon)
  .use(ActionBar)
  .use(Divider)
  .use(NavBar)
  .use(Popup)
  .use(Overlay)
  .use(Loading)
  .use(Dialog)
  .use(Toast)
  .use(ContactCard)
  .use(Form)
  .use(AddressEdit)
  .use(AddressList)
  .use(Field)
  .use(CellGroup)
  .use(Cell)
  .use(SwipeCell)
  .use(Icon)
  .use(Stepper)
  .use(Card)
  .use(Button)
  .use(Image)
  .use(Swipe)
  .use(SwipeItem)
  .use(PullRefresh)
  .use(List)
  .use(Tab)
  .use(Tabs)
  .use(Tag)
  .use(TreeSelect)
  .use(SubmitBar)
  .use(Checkbox)
  .use(CheckboxGroup)
  .use(Skeleton)
  .use(Search) 

app.use(router);
app.use(store);
app.use(Lazyload);
app.mount('#app');


// 全局过滤器
app.config.globalProperties.$filters = {
  prefix(url) {
    if (url && url.startsWith('http')) {
      return url
    } else {
      url = `https://xgma.store${url}`
      return url
    }
  }
}